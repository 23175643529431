import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Helmet from 'react-helmet';
import Layout from '../layouts/index';

const Faqs = (props) => {
  
  const faqs = props.data.allFaqsJson.edges;

  return (
    <Layout bodyClass="page-faqs">
      <SEO title="Frequenty Asked Questions" />
      <Helmet>
        <meta
          name="description"
          content="We connect you with your business world."
        />
      </Helmet>
      <div className="container py-8">
          <div className="row justify-content-start">
            <div className="col-12 text-center">
              <h1 className="title-1 text-primary mb-6">Frequently Asked Questions</h1>
            </div>

            {faqs.map(edge => (
            <div key={edge.node.id} className="col-12">
              <div className="faq">
                <h2 className="faq-title">{edge.node.question}</h2>
                <div className="faq-answer">{edge.node.answer}</div>
              </div>
            </div>
            ))}

           
          </div>
        </div>
    </Layout>
  );
};


export const query = graphql`
  query {
    allFaqsJson {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
  }
`;

export default Faqs;
